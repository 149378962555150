var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"450px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"disabled":_vm.loading,"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("leave.approvals.leave_approval"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":_vm.onClickCancel}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-3"},[_c('v-flex',{attrs:{"xs12":"","mt-2":""}},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t('leave.approvals.message', [
              _vm.employee,
              _vm.leaveType,
              _vm.remainingLeaveBalance,
              _vm.startDate,
              _vm.endDate,
              _vm.requestedCount
            ])
          )}})]),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"name":"note","label":_vm.$t('global.note')},model:{value:(_vm.leaveApprovalDTO.note),callback:function ($$v) {_vm.$set(_vm.leaveApprovalDTO, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"leaveApprovalDTO.note"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-radio-group',{attrs:{"row":"","mandatory":""},model:{value:(_vm.leaveApprovalDTO.approved),callback:function ($$v) {_vm.$set(_vm.leaveApprovalDTO, "approved", $$v)},expression:"leaveApprovalDTO.approved"}},[_c('v-radio',{attrs:{"color":"primary","value":false,"label":_vm.$t('leave.request.denied')}}),_c('v-radio',{attrs:{"color":"primary","value":true,"label":_vm.$t('leave.request.approved')}})],1)],1)],1),_c('v-card-actions',{staticClass:"dialog-footer"},[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.onClickSave.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("buttons.save")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }