<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <v-card :disabled="loading" :loading="loading">
      <v-card-title>
        {{ $t("leave.approvals.leave_approval") }}
        <v-spacer />
        <v-btn depressed icon @click="onClickCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-flex xs12 mt-2>
          <span
            v-html="
              $t('leave.approvals.message', [
                employee,
                leaveType,
                remainingLeaveBalance,
                startDate,
                endDate,
                requestedCount
              ])
            "
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field v-model.trim="leaveApprovalDTO.note" name="note" :label="$t('global.note')" />
        </v-flex>
        <v-flex xs12>
          <v-radio-group row mandatory v-model="leaveApprovalDTO.approved">
            <v-radio color="primary" :value="false" :label="$t('leave.request.denied')" />
            <v-radio color="primary" :value="true" :label="$t('leave.request.approved')" />
          </v-radio-group>
        </v-flex>
      </v-card-text>
      <v-card-actions class="dialog-footer">
        <v-spacer></v-spacer>
        <v-btn depressed @click.stop="onClickSave">{{ $t("buttons.save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "confirm-dialog",
    props: {
      value: Boolean,
      data: Object
    },
    data: () => ({
      dialog: false,
      loading: false,
      employeeLeaveAccruals: null,
      leave: null,
      leaveApprovalDTO: {
        approved: false,
        note: null
      }
    }),
    watch: {
      value: {
        handler(status) {
          this.dialog = status;
        },
        immediate: true
      }
    },
    computed: {
      currentBalance() {
        if (this.employeeLeaveAccruals) {
          return this.employeeLeaveAccruals.leavePolicy.accrualCalculatedCount - this.employeeLeaveAccruals.used;
        } else return "izin hakedişi bulunmamaktadır.";
      },
      employee() {
        return this.data
          ? `${this.data.leaveRequest.employee.firstName} ${this.data.leaveRequest.employee.lastName}`
          : "-";
      },
      leaveType() {
        return this.data ? this.data.leaveRequest.leaveType.name : "";
      },
      startDate() {
        return this.data ? this.$helpers.formattedDate(this.data.leaveRequest.beginLeaveRequestDay.date) : "";
      },
      endDate() {
        return this.data ? this.$helpers.formattedDate(this.data.leaveRequest.endLeaveRequestDay.date) : "";
      },
      remainingLeaveBalance() {
        return this.data ? this.data.leaveRequest.remainingLeaveBalance : "";
      },
      requestedCount() {
        return this.data ? this.data.leaveRequest.deductedLeaveBalance : "";
      }
    },
    methods: {
      onClickCancel() {
        this.leaveApprovalDTO.approved = null;
        this.leaveApprovalDTO.note = null;
        this.leave = null;
        this.$emit("close");
      },
      onClickSave() {
        this.loading = true;
        this.$api.leaveRequestService
          .approve(this.data.id, this.leaveApprovalDTO)
          .then((data) => {
            if (data) {
              this.$emit("success");
              this.leaveApprovalDTO.note = null;
              this.leaveApprovalDTO.note = null;
              this.leave = null;
            }
          })
          .catch((e) => {
            console.log("e", e);
          })
          .then(() => {
            this.loading = false;
          });
      }
    }
  };
</script>

<style scoped></style>
